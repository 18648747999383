import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import { CSSTransition } from 'react-transition-group';
import NewsImg from '../../assets/images/phone/news-example.png';
import './fonts.scss';

const pages = [NewsImg];

const NewsApp = ({
    classes,
    resolving,
    page,
}) => {
    return (
        <Box className={classes.root}>
            <CSSTransition
                in={page === 1}
                timeout={1000}
                classNames={{
                    enterDone: classes.pageInEnter,
                }}
            >
                <Box className={classes.slider}>
                    {pages.map(p => (
                        <img src={p} className={classes.image} alt="Shopping App" />
                    ))}
                </Box>
            </CSSTransition>
        </Box>
    );
};

const styles = theme => ({
    root: {
        position: 'absolute',
        backgroundColor: theme.palette.common.white,
        zIndex: 100,
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        paddingTop: 25,
        overflow: 'hidden',
    },
    image: {
        width: 280,
        position: 'relative',
        top: -21,
    },
    slider: {
        width: 560,
        display: 'flex',
        alignItems: 'flex-start',
        // '& img': {
        //     width: 282,
        // },
        // '&last-child': {

        // }
    },

    pageInEnter: {
        animation: `$slide 1000ms forwards`,
    },

    '@keyframes slide': {
        '0%': {
            transform: 'translate3d(0, 0, 0)',
        },
        '100%': {
            transform: 'translate3d(-280px, 0, 0)',
        },
    },
    // .pageSliderEnter.left > .page {
    //         transform: translate3d(100 %, 0, 0);
    // }
    // //RIGHT transition transform -100% to the left
    // .pageSlider - enter.right > .page {
    //     transform: translate3d(-100 %, 0, 0);
    // }
    // //old exit left direction
    // .pageSlider - exit.left > .page {
    //     transform: translate3d(-100 %, 0, 0);
    // }
    // //old exit right direction
    // .pageSlider - exit.right > .page {
    //     transform: translate3d(100 %, 0, 0);
    // }
    // //Enter is active (Page has finished transitioning) make it settle down
    // .pageSlider - enter.pageSlider - enter - active > .page {
    //     transform: translate3d(0, 0, 0);
    //     //Apply all transitions
    //     transition: all 800ms ease -in -out;
    // }

});

NewsApp.defaultProps = {
    resolving: true,
    page: 0,
};

NewsApp.propTypes = {
    resolving: PropTypes.bool,
    page: PropTypes.number,
};

export default withStyles(styles)(NewsApp);
