import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import MuiContainer from '@material-ui/core/Container';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { Header, Section } from 'pnk-components';
import PhoneScreen from '../../components/PhoneScreen/container';

const Reports = ({
    classes,
    showPhone,
}) => (
    <Section id="reports" backgroundColor="greyLight" classes={{ root: classes.root }}>
        <MuiContainer>
            <Grid
                container
                spacing={4}
                justifyContent="flex-start"
                alignItems="center"
            >
                <Grid item xs={12} sm={6} md={7} lg={5}>
                    <Header
                        title="Access information on the move"
                        overline="Reports"
                    />
                    <Typography
                        variant="body1"
                        gutterBottom
                    >
                        We can deliver reports and documents straight to users’ devices and they can also be notified when a new document is available to be read.
                    </Typography>
                    <Typography
                        variant="body1"
                        gutterBottom
                    >
                        Any reports containing sensitive information can be stored behind a biometric login.
                    </Typography>
                </Grid>
            </Grid>
            {showPhone && (
                <Grid
                    container
                    spacing={4}
                    justifyContent="center"
                    alignItems="center"
                >
                    <Grid item xs={12}>
                        <Box className={classes.phone}>
                            <PhoneScreen />
                        </Box>
                    </Grid>
                </Grid>
            )}
        </MuiContainer>
    </Section>
);

const styles = theme => ({
    root: {
        position: 'relative',
        zIndex: 1,
    },
    phone: {
        marginTop: theme.spacing(4),
        position: 'relative',
        left: 'calc(50% - 160px)',
    },
});

Reports.defaultProps = {
    showPhone: false,
};

Reports.propTypes = {
    showPhone: PropTypes.bool,
};

export default withStyles(styles)(Reports);
