import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import SaleImg from '../../assets/images/phone/product-example.png';
import './fonts.scss';

const ProductApp = ({
    classes,
    resolving,
}) => {
    return (
        <Box className={classes.root}>
            <img src={SaleImg} className={classes.image} alt="Product App" />
        </Box>
    );
};

const styles = theme => ({
    root: {
        position: 'absolute',
        backgroundColor: theme.palette.common.white,
        zIndex: 100,
        top: 5,
        bottom: 0,
        left: 0,
        right: 0,
    },
    image: {
        width: '100%',
    },
});

ProductApp.defaultProps = {
    resolving: true,
};

ProductApp.propTypes = {
    resolving: PropTypes.bool,
};

export default withStyles(styles)(ProductApp);
