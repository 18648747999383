import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import MuiContainer from '@material-ui/core/Container';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { Header, Container } from 'pnk-components';
import ContactForm from '../../components/ContactForm';
import postContact from '../../actions/contact/post-contact';

const Contact = ({ classes }) => {
    const dispatch = useDispatch();
    const status = useSelector((state) => state.contact.get('status'));

    return (
        <Box className={classes.footerContainer}>
            <Container maxWidth={false} className={classes.root} id="contact" name="contact">
                <MuiContainer className={classes.container}>
                    <Grid
                        container
                        spacing={4}
                        justifyContent="space-around"
                        alignItems="center"
                    >
                        <Grid item xs={12} sm={6} md={6}>
                            <Header
                                title="Lets get started"
                                overline="Contact"
                                color="inherit"
                            />
                            <Typography
                                variant="body1"
                                gutterBottom
                                color="inherit"
                            >
                                Just enter your name and email address so we can contact you and start to plan and build your application.
                            </Typography>
                        </Grid>
                        <Grid item xs={12} sm={6} md={5} lg={5}>
                            <ContactForm
                                onSubmit={async (values) => dispatch(await postContact(values))}
                                status={status}
                            />
                        </Grid>
                    </Grid>
                </MuiContainer>
            </Container>
        </Box>
    );
};

const styles = theme => ({
    root: {
        position: 'relative',
        zIndex: 1000,
        backgroundColor: theme.palette.common.white,
        borderTopLeftRadius: 100,
        borderTopRightRadius: 100,
        paddingTop: theme.spacing(7),
        paddingBottom: theme.spacing(7),
        [theme.breakpoints.down('xs')]: {
            borderTopLeftRadius: 25,
            borderTopRightRadius: 25,
        },
    },
    container: {
        // minHeight: 800,
        display: 'flex',
        alignItems: 'center',
        color: theme.palette.text.textTertiary,
    },
    footerContainer: {
        backgroundColor: theme.palette.common.greyLight,
        zIndex: 100,
        position: 'relative',
    },
});

Contact.defaultProps = {

};

Contact.propTypes = {

};

export default withStyles(styles)(Contact);
