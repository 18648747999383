import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Contact from '../../sections/Contact';
import PhoneDemo from '../../sections/PhoneDemo';
import How from '../../sections/How';

/**
 * The home page
 *
 * Hero
 * Services
 * Why
 * Web Products
 * App Product
 * Faqs
 * Contact
 */
const Home = ({ classes }) => (
    <>
        <PhoneDemo />
        <How />
        <Contact />
    </>
);

const styles = theme => ({
    root: {

    },
});

export default withStyles(styles)(Home);
