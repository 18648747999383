import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import MuiContainer from '@material-ui/core/Container';
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { Header, Container } from 'pnk-components';

/**
 *
 * Privacy
 *
 */
const Privacy = ({ classes }) => (
    <Container className={classes.root} maxWidth={false}>
        <MuiContainer>
            <Grid
                container
                spacing={4}
                justifyContent="center"
                alignItems="center"
            >
                <Grid item xs={12}>
                    <Header
                        title="Privacy Policy"
                        overline="PNK"
                        align="center"
                    />
                </Grid>
                <Grid item xs={12} sm={12} md={10}>
                    <Typography variant="body1" color="textTertiary" gutterBottom>This privacy policy sets out how we use and protect any information that you give us when you use this website. When you provide information by which you can be identified, you can be assured that it will only be used in accordance with this privacy statement.</Typography>

                    <Typography variant="h6" color="textTertiary">How we use your personal information</Typography>
                    <Typography variant="body1" color="textTertiary" gutterBottom>The following site functions can request and store your personal information.</Typography>
                    <Divider className={classes.divider} />
                    <Typography variant="h6" color="textTertiary">Security</Typography>
                    <Typography variant="body1" color="textTertiary" gutterBottom>We are committed to ensuring that your information is secure. In order to prevent unauthorised access or disclosure, we have put in place suitable physical, electronic and managerial procedures to safeguard and secure the information we collect online.</Typography>
                    <Divider className={classes.divider} />
                    <Typography variant="h6" color="textTertiary">Controlling your personal information</Typography>
                    <Typography variant="body1" color="textTertiary" gutterBottom>I will not sell, distribute or lease your personal information to third parties.</Typography>
                    <Typography variant="body1" color="textTertiary" gutterBottom>You may request details of personal information which we hold about you under the Data Protection Act 1998. A small fee will be payable. If you would like a copy of the information held on you please write to us at the address below.</Typography>
                    <Typography variant="body1" color="textTertiary" gutterBottom>If you believe that any information I am holding on you is incorrect or incomplete, please write to or email us as soon as possible at the below address. I will promptly correct any information found to be incorrect.</Typography>
                    <Typography variant="body1" color="textTertiary" gutterBottom>I may change this policy from time to time by updating this page. You should check this page from time to time to ensure that you are happy with any changes.</Typography>
                    <Divider className={classes.divider} />
                    <Typography variant="h6" color="textTertiary">Cookies</Typography>
                    <Typography variant="subtitle2" color="textTertiary">How I use cookies</Typography>
                    <Typography variant="body1" color="textTertiary" gutterBottom>A cookie is a tiny text file that a website can store on your machine and can read on subsequent visits. This allows that website to identify that you and, for example, remember to log you in the next time you visit.</Typography>

                    <Typography variant="body1" color="textTertiary" gutterBottom>The only cookies I set are used by Google Analytics to allow us to view anonymous data on how the site is used and where we can make improvements. For example the number of visitors to the site, where visitors have come to the site from and the pages they visited. By using my website, you agree that we can place these types of cookies on your device.</Typography>

                    <Typography variant="body1" color="textTertiary" gutterBottom>Google Analytics uses the following cookies:<br />
                        _utma<br />
                        _utmb<br />
                        _utmc<br />
                        _utmz<br />
                    </Typography>

                    <Typography variant="body1" color="textTertiary" gutterBottom>For more information view Google Analytics’ privacy policy.</Typography>
                    <Divider className={classes.divider} />
                    <Typography variant="h6" color="textTertiary">Deleting Cookies</Typography>
                    <Typography variant="body1" color="textTertiary" gutterBottom>If you wish to remove any cookies, you can do so from your browser. You should be able to find out exactly how this works for your browser by going to its help menu.</Typography>
                    <Divider className={classes.divider} />
                    <Typography variant="h6" color="textTertiary">About PNK Digital</Typography>
                    <Typography variant="body1" color="textTertiary" gutterBottom>PNK Digital is a trading name of Mike Pink Ltd, has its registered office at:<br />
                        7 Jubilee Lane<br />Farnham<br />Surrey<br />GU10 4SZ
                    </Typography>

                    <Typography variant="body1" color="textTertiary" gutterBottom>You can email me via mike@pnk-digital.com</Typography>

                    <Typography variant="body1" color="textTertiary" gutterBottom>Our company registration number is 09029124 and our VAT number is 267834954.</Typography>
                </Grid>
            </Grid>
        </MuiContainer>
    </Container>
);

const styles = theme => ({
    root: {
        display: 'flex',
        alignItems: 'flex-start',
        color: theme.palette.common.white,
        minHeight: 800,
        paddingTop: theme.spacing(20),
        paddingBottom: theme.spacing(8),
    },
    divider: {
        marginTop: theme.spacing(4),
        marginBottom: theme.spacing(4),
    },
});

export default withStyles(styles)(Privacy);
