import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import handleViewport from 'react-in-viewport';
import { withStyles } from '@material-ui/core/styles';

const ViewPort = ({
    classes,
    component,
    inViewport,
    forwardedRef,
    onInViewport,
    onOutViewport,
    position,
}) => {

    useEffect(() => {
        if (inViewport) {
            onInViewport && onInViewport();
        } else {
            onOutViewport && onOutViewport();
        }
    }, [inViewport, onInViewport, onOutViewport]);

    const render = () => {
        return component({ inViewport });
    };

    return (
        <div className={classes.root}>
            {position === 'top' && (<div className={classes.top} ref={forwardedRef} />)}
            {position === 'center' && (<div className={classes.center} ref={forwardedRef} />)}
            {render()}
            {!position === 'bottom' && (<div className={classes.bottom} ref={forwardedRef} />)}
        </div>
    );
};

const styles = theme => ({
    root: {
        position: 'relative',
    },
    top: {
        position: 'absolute',
        top: 0,
    },
    center: {
        position: 'absolute',
        top: '50%',
    },
    bottom: {
        position: 'absolute',
        bottom: 0,
    },
});

ViewPort.defaultProps = {
    position: 'center',
};

ViewPort.propTypes = {
    position: PropTypes.oneOf(['top', 'center', 'bottom']),
};

export default handleViewport(withStyles(styles)(ViewPort));
