import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { withStyles } from '@material-ui/core/styles';
import { bounceOut, bounceIn, zoomInUp, zoomOutDown } from 'react-animations';
import Box from '@material-ui/core/Box';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import useInterval from './useInterval';
import PhoneBackground from '../../assets/images/phone/phone-screen.png';
import LockScreenBackground from '../../assets/images/phone/lock-screen.png';
import FaceIdAlert from '../../assets/images/phone/faceid.png';

import mailAppIcon from '../../assets/images/phone/mail-app-icon.svg';
import messageAppIcon from '../../assets/images/phone/message-app-icon.svg';
import phoneAppIcon from '../../assets/images/phone/phone-app-icon.svg';
import safariAppIcon from '../../assets/images/phone/safari-app-icon.svg';

import Notification from './Notification';
import Map from './Map';
import SaleApp from './SaleApp';
import ProductApp from './ProductApp';
import ReportsApp from './ReportsApp';
import NewsApp from './NewsApp';
import './fonts.scss';

const appIcons = [mailAppIcon, messageAppIcon, phoneAppIcon, safariAppIcon];

const PhoneScreen = ({
    classes,
    notifications,
    locked,
    showApp,
    activeApp,
    appPage,
    faceId,
}) => {
    const [date, setDate] = useState(moment());
    const [displayedNotfications, setDisplayedNotifications] = useState([]);

    useInterval(() => {
        setDate(moment());
    }, 60000);

    useEffect(() => {
        if (displayedNotfications.length > notifications.length) {
            setDisplayedNotifications(displayedNotfications.filter(notification => notifications.findIndex(n => n.id === notification.id) !== -1));
        } else {
            setDisplayedNotifications([...notifications]);
        }
    }, [notifications]);


    const getApp = (state) => {
        switch (activeApp) {
        case 'map': return <Map resolving={state !== 'entered'} />;
        case 'sales': return <SaleApp resolving={false} page={appPage} />;
        case 'product': return <ProductApp resolving={state !== 'entered'} />;
        case 'reports': return <ReportsApp resolving={state !== 'entered'} />;
        case 'news': return <NewsApp resolving={state !== 'entered'} />;
        default: return null;
        }
    };

    return (
        <Box className={classes.root}>
            <Box className={classes.view}>
                <CSSTransition
                    in={showApp}
                    exit={!showApp}
                    timeout={1000}
                    classNames={{ enter: classes.showApp, exitActive: classes.hideApp }}
                    unmountOnExit
                >
                    {state => {
                        return (
                            <Box className={classes.app}>
                                {getApp(state)}
                            </Box>
                        );
                    }}
                </CSSTransition>
                <Box className={classes.screen}>
                    <Box className={classes.appBox}>
                        {appIcons.map((appIcon, index) => <img src={appIcon} alt="App Icon" key={`img${index}`} />)}
                    </Box>
                </Box>
                <CSSTransition
                    in={faceId}
                    exit={!faceId}
                    timeout={1000}
                    classNames={{ enter: classes.showFaceId, exitActive: classes.hideFaceId }}
                    unmountOnExit
                >
                    <Box className={classes.faceId} />
                </CSSTransition>
                <CSSTransition
                    in={locked}
                    exit={!locked}
                    timeout={1000}
                    classNames={{ enter: classes.lock, exitActive: classes.unlock }}
                    unmountOnExit
                >
                    <Box className={`${classes.screen} ${classes.lockScreen}`}>
                        <Box className={classes.time}>{date.format('HH:mm')}</Box>
                        <Box className={classes.date}>{date.format('dddd DD MMMM')}</Box>

                        <Box className={classes.notifications}>
                            <TransitionGroup>
                                {notifications.map((notification) =>
                                    <CSSTransition
                                        key={notification.id}
                                        timeout={300}
                                        classNames={{
                                            enter: classes.notificationIn,
                                            enterActive: classes.notificationInActive,
                                            exit: classes.notificationExit,
                                            exitActive: classes.notificationExitActive,
                                        }}
                                    >
                                        <Notification {...notification} />
                                    </CSSTransition>
                                )}
                            </TransitionGroup>
                        </Box>

                        {locked && <Box className={classes.lockToggle} />}
                    </Box>
                </CSSTransition>
            </Box>
            <Box className={classes.phone} />
        </Box>
    );
};

const styles = theme => ({
    root: {
        position: 'relative',
        width: 400,
        height: 700,
    },
    view: {
        position: 'absolute',
        top: 15,
        left: 17,
        bottom: 90,
        right: 105,
        overflow: 'hidden',
    },
    phone: {
        position: 'absolute',
        top: 0,
        left: 0,
        bottom: 0,
        right: 0,
        zIndex: 101,
        backgroundImage: `url(${PhoneBackground})`,
        backgroundRepeat: 'no-repeat',
        backgroundSize: '100%',
        overflow: 'hidden',
    },
    screen: ({ faceId }) => ({
        position: 'absolute',
        top: 0,
        left: 0,
        bottom: 0,
        right: 0,
        backgroundImage: `url(${LockScreenBackground})`,
        backgroundRepeat: 'no-repeat',
        backgroundSize: '100%',
        filter: faceId ? 'blur(5px)' : 'none',
    }),
    lockScreen: {
        zIndex: 6,
    },
    app: {
        position: 'absolute',
        top: 0,
        left: 0,
        bottom: 0,
        right: 0,
        zIndex: 5,
    },
    hideApp: {
        animation: '$zoomOutDown 1000ms forwards',
    },
    showApp: {
        animation: '$zoomInUp 1000ms forwards',
    },
    '@keyframes zoomInUp': zoomInUp,
    '@keyframes zoomOutDown': zoomOutDown,
    time: {
        position: 'absolute',
        top: 70,
        width: '100%',
        textAlign: 'center',
        fontFamily: 'San Francisco',
        fontWeight: 300,
        fontSize: 60,
        color: theme.palette.common.white,
    },
    date: {
        position: 'absolute',
        top: 145,
        width: '100%',
        textAlign: 'center',
        fontFamily: 'San Francisco',
        fontWeight: 400,
        fontSize: 16,
        color: theme.palette.common.white,
    },
    lockToggle: {
        backgroundColor: theme.palette.common.white,
        width: '40%',
        position: 'absolute',
        top: 580,
        left: '30%',
        height: 3,
        borderRadius: 5,
        animation: '$bounce 1000ms forwards',
    },
    notifications: {
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        position: 'absolute',
        top: 175,
        minHeight: 420,
    },
    notificationIn: {

    },
    notificationInActive: {
        animation: `$bounceIn 1000ms forwards`,
    },
    '@keyframes bounceIn': bounceIn,
    notificationExit: {

    },
    notificationExitActive: {
        animation: `$bounceOut 300ms forwards`,
    },
    '@keyframes bounceOut': bounceOut,
    '@keyframes fadeIn': {
        '0%': {
            opacity: 0,
        },
        '100%': {
            opacity: 100,
        },
    },
    '@keyframes magnify': {
        '0%': {
            transform: 'scale(1)',
        },
        '100%': {
            transform: 'scale(1.5)',
            top: 200,
        },
    },
    '@keyframes bounce': {
        '0%': {
            marginTop: 0,
        },
        '100%': {
            marginTop: 6,
        },
    },

    unlock: {
        animation: `$unlock 500ms 1 forwards`,
    },
    '@keyframes unlock': {
        '0%': {
            top: 0,
        },
        '100%': {
            top: -615,
            filter: 'blur(5px)',
        },
    },
    lock: {
        animation: `$lock 500ms 1 forwards`,
    },
    '@keyframes lock': {
        '0%': {
            top: -615,
            filter: 'blur(5px)',
        },
        '100%': {
            top: 0,
            filter: 'blur(0px)',
        },
    },

    appBox: {
        backgroundColor: 'rgba(255, 255, 255, 0.5)',
        borderRadius: 15,
        padding: 8,
        display: 'flex',
        position: 'absolute',
        bottom: 8,
        left: 16,
        width: 'calc(100% - 28px)',
        justifyContent: 'space-between',
        '& > img': {
            width: 50,
        },
    },
    faceId: {
        position: 'absolute',
        width: 'calc(100% - 80px)',
        top: 100,
        left: 40,
        height: 250,
        backgroundImage: `url(${FaceIdAlert})`,
        backgroundRepeat: 'no-repeat',
        backgroundSize: '100%',
    },
    showFaceId: {
        animation: `$bounceIn 1000ms forwards`,
    },
    hideFaceId: {
        animation: `$bounceOut 300ms forwards`,
    },
});

PhoneScreen.defaultProps = {
    notifications: [],
    locked: true,
    activeApp: null,
    showApp: false,
    appPage: 0,
    faceId: false,
};

PhoneScreen.propTypes = {
    notifications: PropTypes.arrayOf(PropTypes.shape({
        title: PropTypes.string,
        description: PropTypes.string,
    })),
    activeApp: PropTypes.oneOf(['map', 'sales', 'product', 'secure', 'news', 'reports']),
    showApp: PropTypes.bool,
    locked: PropTypes.bool,
    appPage: PropTypes.number,
    faceId: PropTypes.bool,
};

export default withStyles(styles)(PhoneScreen);
