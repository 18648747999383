import React from 'react';
import ReactDOM from 'react-dom';
import { ConnectedRouter } from 'connected-react-router';
import { Provider } from 'react-redux';
import { fromJS } from 'immutable';
import { AppProvider } from 'pnk-components';
import App from './App';
import reportWebVitals from './reportWebVitals';
import store, { history } from './services/store';

const listendToStore = () => {
    const state = fromJS(store.getState());
    console.log('Redux Store Changed', state.toJS()); // eslint-disable-line no-console
};

store.subscribe(listendToStore);

ReactDOM.render(
    <Provider store={store}>
        <ConnectedRouter history={history}>
            <AppProvider>
                <App />
            </AppProvider>
        </ConnectedRouter>
    </Provider>,
    document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
