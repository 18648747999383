import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { withStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import PNKIcon from '../../assets/images/phone/pnk-icon.png';
import './fonts.scss';

moment.updateLocale('en', {
    relativeTime: {
        future: "in %s",
        past: "%s",
        s: 'now',
        ss: '%ds ago',
        m: "a minute",
        mm: "%dm ago",
        h: "an hour",
        hh: "%dh ago",
        d: "a day",
        dd: "%d days",
        w: "a week",
        ww: "%d weeks",
        M: "a month",
        MM: "%d months",
        y: "a year",
        yy: "%d years"
    }
});

const Notification = ({
    classes,
    app,
    title,
    description,
    time,
}) => {
    return (
        <Box className={classes.root}>
            <Box className={classes.header}>
                <Typography variant="body2">
                    <img src={PNKIcon} width="16" alt="App Icon" />
                    {app}
                </Typography>
                <Typography variant="body2">{moment(time).fromNow()}</Typography>
            </Box>
            <Typography variant="body2"><strong>{title}</strong></Typography>
            <Typography variant="body2">{description}</Typography>
        </Box>

    );
};

const styles = theme => ({
    root: {
        // position: 'absolute',
        // top: 175,
        width: 'calc(100% - 24px)',
        textAlign: 'left',
        padding: 10,
        color: theme.palette.common.white,
        backgroundColor: 'rgba(0, 0, 0, 0.5)',
        borderRadius: 12,
        margin: '6px 12px',
        zIndex: 100,
        '& > p': {
            fontFamily: 'San Francisco',
            fontWeight: 500,
            fontSize: 14,
            lineHeight: 1.3,
        }
    },
    header: {
        display: 'flex',
        justifyContent: 'space-between',
        marginBottom: 8,
        '& > p': {
            fontFamily: 'San Francisco',
            fontWeight: 500,
            fontSize: 12,
            lineHeight: 1.3,
        },
        '& > p:first-child': {
            textTransform: 'uppercase',
            display: 'flex',
            alignItems: 'center',
            '& img': {
                marginRight: 6,
            },
        },
    },
    // notificationIn: {
    //     // animation: `$bounce 1000ms steps(12) 1, $magnify 200ms steps(12) 1s 1 forwards`,
    //     animation: `$bounce 1000ms steps(12) 1 forwards`,
    // },
    // '@keyframes bounce': rubberBand,
    // '@keyframes magnify': {
    //     '0%': {
    //         transform: 'scale(1)',
    //     },
    //     '100%': {
    //         transform: 'scale(1.5)',
    //         top: 200,
    //     },
    // }
});

Notification.defaultProps = {
    animationClass: '',
};

Notification.propTypes = {
    animationClass: PropTypes.string,
};

export default withStyles(styles)(Notification);
