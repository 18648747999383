import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { Link as ScrollLink } from 'react-scroll';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import MuiContainer from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { Container, Header } from 'pnk-components';
import PhoneScreen from '../../components/PhoneScreen/container';

const scrollAttrs = {
    activeClass: 'active',
    spy: true,
    smooth: true,
    offset: 0,
    duration: 500,
};

const Hero = ({ classes, showPhone }) => (
    <Container maxWidth={false} className={classes.root} id="home">
        <MuiContainer>
            <Grid
                container
                spacing={4}
                justifyContent="flex-start"
                alignItems="center"
            >
                <Grid item xs={12} sm={6} md={7} lg={5}>
                    <Header
                        title="Your business needs an app!"
                        overline="Work. Smarter."
                        color="inherit"
                    />
                    <Typography
                        variant="h6"
                        color="inherit"
                        gutterBottom
                        className={classes.text}
                    >
                        We specialise in leveraging technology, providing businesses with an affordable way to bring your app to life.
                    </Typography>
                    <Box className={classes.ctaButtons}>
                        <Button
                            color="primary"
                            variant="contained"
                            to="notifications"
                            component={ScrollLink}
                            {...scrollAttrs}
                        >
                            Continue
                        </Button>
                    </Box>
                </Grid>
            </Grid>

            {showPhone && (
                <Grid
                    container
                    spacing={4}
                    justifyContent="center"
                    alignItems="center"
                >
                    <Grid item xs={12}>
                        <Box className={classes.phone}>
                            <PhoneScreen />
                        </Box>
                    </Grid>
                </Grid>
            )}

        </MuiContainer>
    </Container>
);

const styles = theme => ({
    root: {
        height: '100vh',
        position: 'relative',
        minHeight: 800,
        display: 'flex',
        alignItems: 'center',
        color: theme.palette.common.white,
        overflow: 'hidden',
        [theme.breakpoints.down('xs')]: {
            paddingTop: 100,
            height: 'auto',
        },
    },
    phone: {
        marginTop: theme.spacing(4),
        position: 'relative',
        left: 'calc(50% - 160px)',
    },
    text: {
        marginTop: theme.spacing(4),
    },
    ctaButtons: {
        display: 'flex',
        marginTop: theme.spacing(4),
        '& > button': {
            margin: `0 ${theme.spacing(1.5)}px`,
            '&:first-child': {
                marginLeft: 0,
            },
            '&:last-child': {
                marginRight: 0,
            },
        },
    },
});

Hero.defaultProps = {
    showPhone: false,
};

Hero.propTypes = {
    showPhone: PropTypes.bool,
};

export default withStyles(styles)(Hero);
