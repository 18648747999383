import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import MuiContainer from '@material-ui/core/Container';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { Container, Header } from 'pnk-components';
import PhoneScreen from '../../components/PhoneScreen/container';

const News = ({
    classes,
    showPhone,
}) => (
    <Container id="content" maxWidth={false} className={classes.container}>
        <MuiContainer>
            <Grid
                container
                spacing={4}
                justifyContent="flex-start"
                alignItems="center"
            >
                <Grid item xs={12} sm={6} md={7} lg={5}>
                    <Header
                        title="Instant news & content"
                        overline="Content delivery"
                    />
                    <Typography
                        variant="body1"
                        gutterBottom
                    >
                        Do you want to keep your customers up to date with your latest news and information they would be interested in?
                    </Typography>
                    <Typography
                        variant="body1"
                        gutterBottom
                    >
                        This can be instantly delivered to devices and easily read at their convenience just by opening the app.
                    </Typography>
                </Grid>
            </Grid>
            {showPhone && (
                <Grid
                    container
                    spacing={4}
                    justifyContent="center"
                    alignItems="center"
                >
                    <Grid item xs={12}>
                        <Box className={classes.phone}>
                            <PhoneScreen />
                        </Box>
                    </Grid>
                </Grid>
            )}
        </MuiContainer>
    </Container>
);

const styles = theme => ({
    root: {
        position: 'relative',
        zIndex: 1,
    },
    container: {
        height: '100vh',
        position: 'relative',
        minHeight: 800,
        maxWidth: '100%',
        display: 'flex',
        alignItems: 'center',
        color: theme.palette.common.white,
        overflow: 'hidden',
        [theme.breakpoints.down('xs')]: {
            paddingTop: 100,
            height: 'auto',
        },
    },
    phone: {
        marginTop: theme.spacing(4),
        position: 'relative',
        left: 'calc(50% - 160px)',
    },
});

News.defaultProps = {
    showPhone: false,
};

News.propTypes = {
    showPhone: PropTypes.bool,
};

export default withStyles(styles)(News);
