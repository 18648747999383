import { API, graphqlOperation } from 'aws-amplify';
import { createContact } from '../graphql/mutations';

const contactService = {};

contactService.postContact = async (values) => {
    try {
        const response = await API.graphql(graphqlOperation(createContact, { input: { ...values, site: 'pnk.digital' }}));
        return response.data.createContact;
    } catch (error) {
        throw error;
    }
};

export default contactService;
