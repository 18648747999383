export const PHONE_RESET = 'PHONE_RESET';

export const PHONE_ADD_NOTIFICATION = 'PHONE_ADD_NOTIFICATION';
export const PHONE_REMOVE_NOTIFICATION = 'PHONE_REMOVE_NOTIFICATION';
export const PHONE_CLEAR_NOTIFICATIONS = 'PHONE_CLEAR_NOTIFICATIONS';

export const PHONE_LOCK = 'PHONE_LOCK';
export const PHONE_UNLOCK = 'PHONE_UNLOCK';

export const PHONE_OPEN_APP = 'PHONE_OPEN_APP';
export const PHONE_CLOSE_APP = 'PHONE_CLOSE_APP';

export const PHONE_CHANGE_APP_PAGE = 'PHONE_CHANGE_APP_PAGE';

export const PHONE_SHOW_FACEID = 'PHONE_SHOW_FACEID';
export const PHONE_HIDE_FACEID = 'PHONE_HIDE_FACEID';
