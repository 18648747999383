import React from 'react';
// import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import MuiContainer from '@material-ui/core/Container';
import Hidden from '@material-ui/core/Grid';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { Section, Header } from 'pnk-components';
import BodyImage from '../../assets/images/thinking.png';

const How = ({ classes }) => (
    <Section id="how" backgroundColor="greyLight" classes={{ root: classes.root }}>
        <MuiContainer>
            <Grid
                container
                spacing={4}
                justifyContent="flex-start"
                alignItems="center"
            >
                <Grid item xs={12} sm={6} md={6} lg={5}>
                    <Header
                        title="Why do we offer this?"
                        overline="Our mission"
                    />
                    <Typography
                        variant="body1"
                        gutterBottom
                    >
                        In our experience many businesses are supplied needlessly complex website solutions with content management systems, hosting servers and databases.
                    </Typography>
                    <Typography
                        variant="body1"
                        gutterBottom
                    >
                        We like to offer simple efficient online solutions which meet your requirements with less cost and less bloat.
                    </Typography>
                    <Typography
                        variant="body1"
                        gutterBottom
                    >
                        It's our mission to provide a friendly no-nonsense service to help bring businesses online.
                    </Typography>
                </Grid>
                <Grid item md={4} lg={3} implementation="css" component={Hidden} />
            </Grid>
        </MuiContainer>
    </Section>

);

const styles = theme => ({
    root: {
        position: 'relative',
        zIndex: 100,
        '&:before': {
            content: '""',
            position: 'absolute',
            bottom: 0,
            right: 70,
            width: 800,
            height: 715,
            backgroundImage: `url(${BodyImage})`,
            backgroundRepeat: 'no-repeat',
            backgroundSize: '100%',
            zIndex: -1,
            [theme.breakpoints.down('md')]: {
                right: 50,
                width: 630,
                height: 545,
            },
            [theme.breakpoints.down('sm')]: {
                width: 350,
                height: 410,
            },
            [theme.breakpoints.down('xs')]: {
                width: 220,
                height: 200,
            },
        },
    },
});

How.defaultProps = {

};

How.propTypes = {

};

export default withStyles(styles)(How);
