import React from 'react';
import PropTypes from 'prop-types';
import GoogleMapReact from 'google-map-react';
import { withStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import './fonts.scss';

const MapApp = ({
    classes,
    resolving,
}) => {
    const mapConfig = {
        center: {
            lat: 51.21120071,
            lng: -0.79414835
        },
        zoom: 11
    };

    return (
        <Box className={classes.root}>
            {!resolving && (
                <>
                    <GoogleMapReact
                        bootstrapURLKeys={{ key: 'AIzaSyCG421HPzG8O6aTs-uAslGSLZvuwsVloR8' }}
                        defaultCenter={mapConfig.center}
                        defaultZoom={mapConfig.zoom}
                    >
                    </GoogleMapReact>
                </>
            )}
        </Box>
    );
};

const styles = theme => ({
    root: {
        position: 'absolute',
        backgroundColor: theme.palette.common.white,
        zIndex: 100,
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
    },
});

MapApp.defaultProps = {
    resolving: true,
};

MapApp.propTypes = {
    resolving: PropTypes.bool,
};

export default withStyles(styles)(MapApp);
