import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import moment from 'moment';
import { withStyles, useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Box from '@material-ui/core/Box';
import {
    PHONE_ADD_NOTIFICATION,
    PHONE_REMOVE_NOTIFICATION,
    PHONE_LOCK,
    PHONE_UNLOCK,
    PHONE_OPEN_APP,
    PHONE_CLOSE_APP,
    PHONE_RESET,
    PHONE_CHANGE_APP_PAGE,
    PHONE_SHOW_FACEID,
    PHONE_HIDE_FACEID,
} from '../../actions/phone/types';
import PhoneScreen from '../../components/PhoneScreen/container';
import Hero from '../../sections/Hero';
import Notification from '../Notification';
import Shop from '../Shop';
import Reports from '../Reports';
import News from '../News';
import Secure from '../Secure';
import ViewPort from '../../components/ViewPort';

const processStep = (section, previousSection, dispatch) => {
    console.log('>>', section, previousSection);
    if (section === 0) {
        dispatch({
            type: PHONE_RESET,
        });
        setTimeout(() => {
            dispatch({
                type: PHONE_ADD_NOTIFICATION,
                payload: {
                    id: 1,
                    app: 'PNK Digital',
                    title: 'Welcome',
                    description: 'Let us help you reach your customers!',
                    time: moment().format(),
                },
            });
        }, 300);
    } else if (section === 1) {
        if (previousSection === 0) {
            dispatch({
                type: PHONE_REMOVE_NOTIFICATION,
            });
            setTimeout(() => {
                dispatch({
                    type: PHONE_ADD_NOTIFICATION,
                    payload: {
                        id: 2,
                        app: 'Shop',
                        title: 'Sale now on!',
                        description: '10% off for app users only!',
                        time: moment().format(),
                    },
                });
            }, 400);
            setTimeout(() => {
                dispatch({
                    type: PHONE_ADD_NOTIFICATION,
                    payload: {
                        id: 3,
                        app: 'Shop',
                        title: 'Check out our new bags! 👜',
                        description: 'Get in first before they sell out!',
                        time: moment().format(),
                    },
                });
            }, 1000);
        } else {
            dispatch({
                type: PHONE_LOCK,
            });
        }
    } else if (section === 2) {
        dispatch({
            type: PHONE_UNLOCK,
        });
        dispatch({
            type: PHONE_CLOSE_APP,
        });
        setTimeout(() => {
            dispatch({
                type: PHONE_OPEN_APP,
                payload: 'sales',
            });
        }, 300);
        setTimeout(() => {
            dispatch({
                type: PHONE_CHANGE_APP_PAGE,
                payload: 1,
            });
        }, 2000);
    } else if (section === 3) {
        dispatch({
            type: PHONE_CLOSE_APP,
        });
        setTimeout(() => {
            dispatch({
                type: PHONE_OPEN_APP,
                payload: 'reports',
            });
        }, 300);
    } else if (section === 4) {
        dispatch({
            type: PHONE_HIDE_FACEID,
        });
        dispatch({
            type: PHONE_CLOSE_APP,
        });
        setTimeout(() => {
            dispatch({
                type: PHONE_OPEN_APP,
                payload: 'news',
            });
        }, 300);
    } else if (section === 5) {
        dispatch({
            type: PHONE_CLOSE_APP,
        });
        setTimeout(() => {
            dispatch({
                type: PHONE_SHOW_FACEID,
            });
        }, 300);
    }
};


const PhoneDemo = ({
    classes,
}) => {
    const [previousSection, setPreviousSection] = useState(-1);
    const [currentSection, setCurrentSection] = useState(0);
    const dispatch = useDispatch();
    const theme = useTheme();
    const xs = useMediaQuery(theme.breakpoints.down('xs'));

    useEffect(() => {
        processStep(currentSection, previousSection, dispatch);
    }, [currentSection, dispatch]);

    const handleViewPortChange = (section) => {
        console.log('section', section);
        setPreviousSection(currentSection);
        setCurrentSection(section);
    };

    return (
        <Box className={classes.root}>
            <ViewPort
                onInViewport={() => handleViewPortChange(0)}
                position="top"
                component={() => <Hero showPhone={xs} />}
            />
            <ViewPort
                onInViewport={() => handleViewPortChange(1)}
                component={() => <Notification showPhone={xs} />}
            />
            <ViewPort
                onInViewport={() => handleViewPortChange(2)}
                component={() => <Shop showPhone={xs} />}
            />
            <ViewPort
                onInViewport={() => handleViewPortChange(3)}
                component={() => <Reports showPhone={xs} />}
            />
            <ViewPort
                onInViewport={() => handleViewPortChange(4)}
                component={() => <News showPhone={xs} />}
            />
            <ViewPort
                onInViewport={() => handleViewPortChange(5)}
                component={() => <Secure showPhone={xs} />}
            />
            {!xs && (
                <Box className={classes.phone}>
                    <PhoneScreen />
                </Box>
            )}
        </Box>
    );
};

const styles = theme => ({
    root: {
        position: 'relative',
        width: '100%',
        overflowX: 'hidden',
    },
    phone: {
        position: 'fixed',
        width: 400,
        height: 700,
        zIndex: 10,
        top: '40%',
        right: '20%',
        transform: 'scale(1.5)',
        [theme.breakpoints.down('lg')]: {
            right: '10%',
        },
        [theme.breakpoints.down('md')]: {
            top: '25%',
            right: 0,
            transform: 'scale(1)',
        },
        [theme.breakpoints.down('sm')]: {
            right: -50,
        },
        [theme.breakpoints.down('xs')]: {
            right: -100,
        },
    },
});

PhoneDemo.defaultProps = {

};

PhoneDemo.propTypes = {

};

export default withStyles(styles)(PhoneDemo);
