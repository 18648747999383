import { connect } from 'react-redux';
import PhoneScreen from './PhoneScreen';

const mapStateToProps = state => ({
    notifications: state.phone.get('notifications').toJS(),
    locked: state.phone.get('locked'),
    activeApp: state.phone.get('activeApp'),
    showApp: state.phone.get('showApp'),
    appPage: state.phone.get('appPage'),
    faceId: state.phone.get('faceId'),
});

export default connect(mapStateToProps)(PhoneScreen);
