import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { ScrollToTop } from 'pnk-components';
import Amplify from 'aws-amplify';
import Page from './pages/Page';
import Home from './pages/Home';
import News from './pages/News';
import Privacy from './pages/Privacy';
import NewsItem from './pages/NewsItem';
import awsconfig from './aws-exports';
/** Configure amplify */
Amplify.configure(awsconfig);

class App extends React.Component {
    render = () => {
        return (
            <ScrollToTop>
                <Page>
                    <Switch>
                        <Route
                            path="/"
                            exact
                            component={Home}
                        />
                        <Route
                            path="/news"
                            exact
                            component={News}
                        />
                        <Route
                            path="/news/:id"
                            exact
                            component={NewsItem}
                        />
                        <Route
                            path="/privacy-policy"
                            exact
                            component={Privacy}
                        />
                    </Switch>
                </Page>
            </ScrollToTop>

        );
    };
};

export default App;
