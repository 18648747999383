import {
    createStore,
    combineReducers,
    applyMiddleware,
    compose,
} from 'redux';
import { connectRouter, routerMiddleware } from 'connected-react-router';
import { createBrowserHistory } from 'history';
import thunk from 'redux-thunk';
import { reducer as formReducer } from 'redux-form';

// Import reducers
import applicationReducer from '../reducers/application';
import articlesReducer from '../reducers/articles';
import contactReducer from '../reducers/contact';
import phoneReducer from '../reducers/phone';

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

// Get middleware for history
const history = createBrowserHistory();

// Build middleware
const middleware = composeEnhancers(
    applyMiddleware(
        routerMiddleware(history),
        thunk,
    ),
);

// Combine the reducers
const reducers = combineReducers({
    application: applicationReducer,
    articles: articlesReducer,
    contact: contactReducer,
    phone: phoneReducer,
    form: formReducer,
    router: connectRouter(history),
});

// Make our store with our reducers and middleware
const store = createStore(
    reducers,
    middleware,
);

export { history };

export default store;
