import * as Immutable from 'immutable';
import {
    PHONE_RESET,
    PHONE_ADD_NOTIFICATION,
    PHONE_CLEAR_NOTIFICATIONS,
    PHONE_REMOVE_NOTIFICATION,
    PHONE_LOCK,
    PHONE_UNLOCK,
    PHONE_OPEN_APP,
    PHONE_CLOSE_APP,
    PHONE_CHANGE_APP_PAGE,
    PHONE_SHOW_FACEID,
    PHONE_HIDE_FACEID,
} from '../actions/phone/types';

const schema = {
    notifications: Immutable.List(),
    locked: true,
    showApp: false,
    activeApp: null,
    appPage: 0,
    faceId: false,
};

const initialState = Immutable.Map(schema);

/**
 * The Application reducer
 * @param {Object} state
 * @param {Object} action
 */
const phoneReducer = (state = initialState, action) => {
    switch (action.type) {
        case PHONE_ADD_NOTIFICATION:
            return state.update('notifications', notifications => notifications.push(action.payload));
        case PHONE_REMOVE_NOTIFICATION:
            return state.update('notifications', notifications => notifications.shift());
        case PHONE_CLEAR_NOTIFICATIONS:
            return state.set('notifications', Immutable.List());
        case PHONE_LOCK:
            return state.set('locked', true);
        case PHONE_UNLOCK:
            return state.set('locked', false);
        case PHONE_OPEN_APP:
            return state
                .set('activeApp', action.payload)
                .set('showApp', true);
        case PHONE_CLOSE_APP:
            return state
                .set('showApp', false);
        case PHONE_CHANGE_APP_PAGE:
            return state
                .set('appPage', action.payload);
        case PHONE_SHOW_FACEID:
            return state
                .set('faceId', true);
        case PHONE_HIDE_FACEID:
            return state
                .set('faceId', false);
        case PHONE_RESET:
            return initialState;
        default:
            return state;
    }
};

export { initialState };

export default phoneReducer;
